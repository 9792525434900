<template>
	<div>
		<header class="modal-card-head">
			<h4 class="modal-card-title ellipsis">
				<strong class="is-block">{{ criteriaName }}</strong>
				<span class="is-contest">Histórico de Puntuación</span>
			</h4>
		</header>
		<div class="modal-card-body modal-select chat-modal">
			<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
			<div v-for="h in historic" :key="h.id" class="text">
                <div class="profile-pic">
                    <img :src="h.user.avatar" />
                </div>
                <div class="text-wrapper">
                    <span class="username">{{ h.user.full_name }}</span>
                    <div class="text-content">
                        <span>Cambió la nota de <strong class="old">{{ h.note_old }}</strong> para <strong class="new">{{ h.note_new }}</strong>.</span>
                    </div>
                    <span class="timestamp">
                        {{ h.date }} • {{ h.hour }}
                    </span>
                </div>
            </div>
		</div>
		<footer class="modal-card-foot">
			<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Cerrar</b-button>
		</footer>
	</div>
</template>

<script>
export default {
	props: {
		criteriaName: {
			required: true
		},
		historic: {
			required: true
		}
	},
	data() {
		return {
			isOpening: false
		}
	}
}
</script>
