<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form @submit.prevent="handleSubmit(saveRating)">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>
						{{ title }}
						<strong>Evaluación</strong>
					</span>
					<small v-if="title == 'Nueva' && isOpening == false" v-html="total"></small>
					<span v-if="rating.id">#{{ rating.id }}</span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="columns is-multiline is-mobile mb-2">
					<div class="column is-12-mobile is-12-tablet">
						<InputWithValidation v-model="rating.name" rules="required|min:3" type="text" label="Nombre" size="is-medium" />
					</div>
				</div>
				<div class="columns">
					<div class="column is-12-mobile is-3-tablet">
						<b-field label="Inicio">
							<b-datepicker v-model="rating.start" locale="es-MX" icon-right="calendar-today" trap-focus></b-datepicker>
						</b-field>
					</div>
					<div class="column is-12-mobile is-3-tablet">
						<b-field label="Fin">
							<b-datepicker :min-date="rating.start" v-model="rating.end" locale="es-MX" icon-right="calendar-today" trap-focus></b-datepicker>
						</b-field>
					</div>
					<div class="column is-12-mobile is-6-tablet">
						<div class="field">
							<label class="label">Política de Bonificación</label>
							<div class="control input is-medium fake">
								<div class="files">
									<b-tag v-for="(f, i) in rating.files" class="mr-2" :key="i" type="is-info" size="is-small" closable @close="removeFile(i)">
										<span v-if="title == 'Nueva'" v-html="$options.filters.filename(f.name, f)"></span>
										<span v-if="title == 'Editar'" v-html="$options.filters.filenameEdit(f.name, f)"></span>
									</b-tag>
								</div>
								<b-upload class="button is-primary" v-model="rating.files" ref="files" :required="verifyRequiredFiles()" multiple>
									<svg-icon class="icon is-small" icon="export"></svg-icon>
									Adjuntar
								</b-upload>
							</div>
						</div>
					</div>
				</div>
				<h3 class="is-size-5 is-medium has-text-primary">Criterios</h3>
				<hr class="my-3" />
				<div v-for="g in rating.groups" class="modal-groups" :class="{ eligibility: g.eligibility }" :key="g.id">
					<h4 class="modal-groups__group mt-5" :class="{ pointer: name == 'New' }" @click="activeAll(g.groups)">
						<span class="is-uppercase">{{ g.name }}</span>
					</h4>
					<div class="columns is-multiline is-mobile mt-1 mb-5">
						<div v-for="c in g.groups" :key="c.id" :title="c.name" @click.self="changeState(c)" :class="{ active: !!c.status }" class="modal-groups__item column is-12-mobile is-6-tablet">
							<span class="modal-groups__name no-events">
								<span class="is-block is-uppercase">{{ c.name }}</span>
							</span>
							<span v-if="!g.eligibility" class="modal-groups__points">{{ getWeight(c) }}</span>
							<b-field v-if="name == 'New'">
								<b-switch passive-type="is-gray" type="is-success" v-model="c.status" />
							</b-field>
						</div>
					</div>
				</div>
				<small class="modal-updated" v-if="rating.updated_at">Última Actualización: {{ format(rating.updated_at) }}</small>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">Cerrar</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">Guardar</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import '@/mixins/date'
import Icon from '@/components/Icon'

export default {
	components: {
		InputWithValidation,
		ValidationObserver,
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: false
		},
		name: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
			rating: {
				files: [],
				groups: []
			}
		}
	},
	methods: {
		getWeight(criteria) {
			let weight = 0

			if (criteria.equivalence == 0) {
				weight = criteria.weight
			} else {
				let eq = [...criteria.equivalences]
				weight = eq.pop().index
			}

			return parseInt(weight)
		},
		verifyRequiredFiles() {
			return !(this.name === 'Edit' && this.rating.files.length > 0)
		},
		removeFile(i) {
			this.rating.files.splice(i, 1)
		},
		changeState(c) {
			c.status = !c.status
			console.log(c)
		},
		activeAll(g) {
			for (let c in g) {
				g[c].status = !g[c].status
			}
		},
		async getAllCategories() {
			this.isOpening = true
			if (this.name !== 'Edit') {
				try {
					const response = await Api.get('criteria-group/findAll')
					const { status } = response
					if (status === 200) {
						const { data } = response
						// Se não tem a chave 'status' no JSON, adiciona automaticamente e seta como FALSE
						for (let i in data) {
							for (let g in data[i].groups) {
								data[i].groups[g].status = true
							}
						}
						this.rating.groups = data
					}
				} catch (e) {
					console.log(e)
				} finally {
					this.isOpening = false
				}
			}
		},
		async saveRating() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async store() {
			try {
				const formData = this.getFormData()
				this.loading = true
				const response = await Api.post(`rating/store`, formData)
				const { status } = response
				if (status === 201) {
					this.$emit('close')
					history.pushState({}, '', '/ratings')
					successToast('¡La evaluación fue <strong>registrada</strong> exitosamente!')
					eventHub.$emit('reload-ratings')
				}
			} catch (e) {
				console.log(e)
				const { status } = e
				if (status === 422) {
					errorToast('¡Ocurrió un <strong>error</strong> al crear la reseña!')
				}
				if (status === 413) {
					errorToast('¡¡Ups!! El documento es demasiado pesado. ¡Intenta adjuntar otro!')
				}
			} finally {
				this.loading = false
			}
		},
		async update() {
			try {
				this.loading = true
				var formData = this.getFormData()

				for (var j in this.$refs.files.value) {
					if (typeof this.$refs.files.value[j] === 'string') {
						formData.append(`stringFiles[]`, this.$refs.files.value[j])
					} else {
						formData.append(`files[]`, this.$refs.files.value[j])
					}
				}
				formData.append('_ method', 'PUT')

				const response = await Api.post(`rating/update/${this.id}`, formData)
				const { status } = response
				if (status === 200) {
					this.$emit('close')
					history.pushState({}, '', '/ratings')
					successToast('¡La revisión fue <strong>actualizada</strong> exitosamente!')
					eventHub.$emit('reload-ratings')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast('¡Ocurrió un <strong>error</strong> al actualizar la reseña!')
				}
				if (status === 413) {
					errorToast('¡¡Ups!! El documento es demasiado pesado. Intenta adjuntar otro.')
				}
			} finally {
				this.loading = false
			}
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`rating/findById/${this.id}`)
					const { status } = response
					if (status === 200) {
						const { data } = response
						data.start = this.dateWithoutTimezone(data.start)
						data.end = this.dateWithoutTimezone(data.end)
						this.rating = data
						for (let i in this.rating.groups) {
							for (let g in this.rating.groups[i].groups) {
								this.rating.groups[i].groups[g].status = this.rating.groups[i].groups[g].active
							}
						}
					}
				} catch (e) {
					errorToast('¡Ocurrió un <strong>error</strong> al abrir la reseña!')
					this.$emit('close')
				} finally {
					this.loading = false
					this.isOpening = false
				}
			}
		},
		dateWithoutTimezone(originalDate) {
			let date = new Date(originalDate)
			date.setMinutes(date.getMinutes() + date.getTimezoneOffset())
			return date
		},
		getFormData() {
			const formData = new FormData()
			var value
			try {
				let rating = this.rating
				delete rating.status
				for (var i in this.rating) {
					value = this.rating[i]

					if (i == 'start' || i == 'end') {
						value = new Date(value).toISOString().split('T')[0]
					}

					if (i == 'files') {
						for (var j in this.$refs.files.value) {
							formData.append(`${i}[]`, this.$refs.files.value[j])
						}
					} else {
						if (typeof value === 'object' && value !== null) {
							var objectToSend = []
							value.forEach(group => {
								objectToSend.push({ name: group.name, id: group.id, eligibility: group.eligibility, groups: group.groups })
							})
							formData.append(`${i}`, JSON.stringify(objectToSend))
						} else {
							formData.append(`${i}`, value)
						}
					}
				}

				return formData
			} catch (e) {
				console.log(e)
			}
		}
	},
	filters: {
		filename(file, file2) {
			if (!file) file = file2

			let f = file.split('.'),
				name = f[0].slice(0, 5),
				ext = f[1]

			return name + '.' + '<span>' + ext + '</span>'
		},
		filenameEdit(file, file2) {
			if (!file) file = file2

			let f = file.split('.'),
				name = f[0].slice(8, 13),
				ext = f[1]

			return name + '.' + '<span>' + ext + '</span>'
		},
		toNumber: function(value) {
			let n = value
			return n.length >= 2 ? n.split('.')[0] : n
		}
	},
	computed: {
		title() {
			return this.modalTitle(this.name)
		},
		total() {
			let selected = [],
				weight = 0,
				c = this.rating.groups

			for (let i in c) {
				const group = c[i]
				for (let g in c[i].groups) {
					let category = c[i].groups[g]
					selected.push(category.status)
					if (category.status && !group.eligibility) {
						weight += this.getWeight(category)
					}
				}
			}
			selected = selected.filter(Boolean).length

			let plural = selected > 1 ? 's' : ''

			return selected >= 1 ? `<strong>${selected}</strong> criterio${plural} <span class="has-text-grey-lighter">|</span> <strong>${weight}</strong> punto${plural}` : null
		}
	},
	mounted() {
		this.findById()
		this.getAllCategories()
	}
}
</script>
