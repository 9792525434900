<template>
	<b-dropdown class="block__dropdown" trigger="click" position="is-bottom-left" v-if="permissionEnabled('ratings', 'edit') || permissionEnabled('ratings', 'delete') || (permissionEnabled('ratings', 'read') && user.permission.slug == 'manager')">
		<svg-icon class="dots" slot="trigger" icon="dots"></svg-icon>
		<b-dropdown-item v-for="(l, i) in items" :key="i" :class="l.color ? l.color : 'has-text-grey-light'" v-show="permissionEnabled('ratings', l.type)" @click="handleClick(l, id)">
			<span v-if="l.id == 6 || l.id == 4" class="dropdown-label">{{ section(l.id) }}</span>
			<div>
				<svg-icon :icon="l.icon"></svg-icon>
				<span>{{ nameAction(l.name) }}</span>
			</div>
		</b-dropdown-item>
	</b-dropdown>
</template>

<script>
import Icon from '@/components/Icon'
import eventHub from '@/services/eventHub'
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'Trigger',
	components: {
		'svg-icon': Icon
	},
	props: {
		id: {
			type: [Number, String],
			required: true
		},
		items: {
			type: Array,
			required: false,
			default: () => {
				return [
					{
						id: 6,
						name: 'Dealers',
						icon: 'profile',
						color: 'has-text-info has-label',
						type: 'read'
					},
					{
						id: 7,
						name: 'Criterios',
						icon: 'criteria',
						color: 'has-text-info',
						type: 'read'
					},
					{
						id: 9,
						name: 'Disputas',
						icon: 'contest',
						type: 'read',
						color: 'has-text-warning'
					},
					{
						id: 4,
						name: 'Exportar',
						icon: 'export',
						type: 'read',
						color: 'has-text-info has-label'
					},
					{
						id: 1,
						name: 'Editar',
						icon: 'edit',
						type: 'edit',
						color: 'has-text-grey-light'
					},
					{
						id: 5,
						name: 'Eliminar',
						icon: 'trash',
						color: 'has-text-danger',
						type: 'delete'
					}
				]
			}
		}
	},
	methods: {
		section(id) {
			return id == 6 ? 'Evaluación' : 'Acciones'
		},
		handleClick(item, id) {
			const events = {
				1: 'edit-rating',
				5: 'delete-rating',
				6: 'start-rating',
				7: 'rate-categories',
				9: 'list-contests',
				4: 'export-rating'
			}

			eventHub.$emit(events[item.id], { id })
		},
		nameAction(name) {
			if ((name == 'Avaliação' || name == 'Evaluación') && this.user.permission.slug == 'user') {
				return 'Aceptar'
			}

			return name
		}
	},
	computed: {
		...mapGetters('user', ['permissionEnabled']),
		...mapState('user', ['user'])
	}
}
</script>
